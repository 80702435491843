// FilterDropdown.js
import React from 'react';
import '../css/Filters.css';

const Filters = ({ projects, selectedTags, onSelectTag }) => {

  const allTags = Array.from(new Set(projects.flatMap((project) => project.tags)));

  return (
    <div className="filter-dropdown">
        <div className="tag-container">
          {allTags.map((tag) => (
            <span
              key={tag}
              className={selectedTags.includes(tag) ? 'selected' : ''}
              onClick={() => onSelectTag(tag)}
            >
              {tag}
            </span>
          ))}
        </div>
    </div>
  );
};

export default Filters;
