import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math'; // Import remark-math for LaTeX math support
import rehypeKatex from 'rehype-katex'; // Import rehype-katex for rendering LaTeX math
import 'katex/dist/katex.min.css'; // Import KaTeX CSS for styling LaTeX math
import '../css/BlogPost.css';
import config from '../config';

const BlogPost = () => {
  const { year, month, day, title } = useParams();
  const id = `${year}:${month}:${day}:${title}`;

  const [metadata, setMetadata] = useState({});
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [metadataResponse, contentResponse] = await Promise.all([
          fetch(`${config.apiUrl}/api/blogmetadata/${id}`),
          fetch(`${config.apiUrl}/api/blog/${id}`)
        ]);
        const [metadataData, contentData] = await Promise.all([
          metadataResponse.json(),
          contentResponse.json()
        ]);
        setMetadata(metadataData);
        setContent(contentData.content);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="blog-post-container">
      <div className="blog-post-content">
        <h2>{metadata.title}</h2>
        <div className='header-divider-light' />
        <p className='published-date'>{`Published on ${year}/${month}/${day}`}</p>
        <ReactMarkdown
          remarkPlugins={[gfm, remarkMath]} // Include remark-math for LaTeX math support
          rehypePlugins={[rehypeRaw, rehypeKatex]} // Include rehype-katex for rendering LaTeX math
        >
          {content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default BlogPost;
