import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GeistProvider, CssBaseline } from '@geist-ui/core';
import ReactGA from "react-ga";

// Initialize GA4
ReactGA.initialize("G-8ESBTQ0XLZ");

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <GeistProvider>
      <CssBaseline />
      <App />
    </GeistProvider>
  </React.StrictMode>
);

// Call ReactGA.pageview() to send the initial pageview event
ReactGA.pageview(window.location.pathname + window.location.search);

const SendAnalytics = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint.
// Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
