// HomePage.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/BlogPage.css'; 
import '../css/BlogPost.css'; 
import '../css/HomePage.css';
import config from '../config';
import { Footer } from 'flowbite-react';

import { useNavigate } from 'react-router-dom';

const BlogPage = () => {
  const [metadata, setMetadata] = useState([]);
  const [page, setPage] = useState(1);

  const [mainArticle, setMainArticle] = useState({});
  const [smallArticles, setSmallArticles] = useState([]);
  

  useEffect(() => {
    const fetchSmallArticles = async () => {
      try {
        // Fetch metadata for the small articles
        const smallArticlesResponse = await fetch(`${config.apiUrl}/api/blog-previews`);
        const smallArticlesData = await smallArticlesResponse.json();
        const sortedArticles = smallArticlesData.sort((a, b) => {
          const [aYear, aMonth, aDay] = a.postID.split(':').map(Number);
          const [bYear, bMonth, bDay] = b.postID.split(':').map(Number);
         
          if (aYear !== bYear) {
            return bYear - aYear;
          }
         
          if (aMonth !== bMonth) {
            return bMonth - aMonth; 
          }
          
          return bDay - aDay; 
  
        });
        setMainArticle(sortedArticles[0]);
        setSmallArticles(sortedArticles.slice(1,5));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchSmallArticles();
  }, []);

  const navigate = useNavigate();

  const handleArticleClick = (postID) => {
    const [year, month, day, title] = postID.split(':');
    const route = `/blog/${year}/${month}/${day}/${title}`;
    navigate(route);
  }


  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const metadataResponse = await fetch(`${config.apiUrl}/api/blog-previews?page=${page}`);
        const metadataData = await metadataResponse.json();
        // Sort metadata based on timestamp in descending order
        const sortedMetadata = metadataData.sort((a, b) => b.timestamp - a.timestamp);
        setMetadata(sortedMetadata);
      } catch (error) {
        console.error('Error fetching blog metadata:', error);
      }
    };
  
    fetchMetadata();
  }, [page]);
  
  const loadMore = async () => {
    try {
      const metadataResponse = await fetch(`${config.apiUrl}/api/blog-previews?page=${page}&lastTimestamp=${getLastTimestampOfLastItem()}`);
      const metadataData = await metadataResponse.json();
  
      if (!metadataData || metadataData.error) {
        // Handle error response from the backend
        console.error('Error fetching blog previews:', metadataData.error);
        // Optional: Notify the user about the error (toast, alert, etc.)
        return;
      }
  
      // Check if there are no more items
      if (metadataData.length === 0) {
        console.warn('No more blog previews available.');
        // Optional: Notify the user that no more items are available (toast, alert, etc.)
        return;
      }
  
      console.log(metadataData);

      setMetadata((prevMetadata) => [...prevMetadata, ...metadataData]);
      setPage(page + 1);
    } catch (error) {
      console.error('Error fetching blog previews:', error);
      // Optional: Notify the user about the error (toast, alert, etc.)
    }
  };
  
  // Function to get the timestamp of the last item in the current page
  const getLastTimestampOfLastItem = () => {
    if (metadata.length === 0) {
      return undefined; 
    }
  
    const lastItem = metadata[metadata.length - 1];
    return lastItem.timestamp;
  };
  
  return (
    <div className='blog-container'>
    <div className="header-divider" />
      <div className="landingPage">
      {mainArticle.postID && <Link to={`/blog/${mainArticle.postID.split(':')[0]}/${mainArticle.postID.split(':')[1]}/${mainArticle.postID.split(':')[2]}/${mainArticle.postID.split(':')[3]}`} className="custom-link">
        <div className='main-article-container'>
          <div className="main-image-container">
            <img src={mainArticle.thumbnailUrl} alt="mainImage" />
          </div>
          <div className="main-text-container">
            <div className="main-title-conatiner">
              <h2>{mainArticle.title}</h2>
            </div>
            <div className="main-preview-container">
              {mainArticle.postID && <p className='preview-date'>{`${mainArticle.postID.split(':')[1]}/${mainArticle.postID.split(':')[2]}/${mainArticle.postID.split(':')[0]}`}</p>  }
              <p className='mainpreviewtext'>{mainArticle.preview}</p>
            </div>
          </div>
        </div>  
      </Link> }
        <div className="header-divider-light" />
        <div className='small-articles-container'>
          {smallArticles.map((article) => (
            
              <div key={article.postID} className='small-article' onClick={() => handleArticleClick(article.postID)}>
                <img src={article.thumbnailUrl} alt={article.title} className='small-article-image' />
                <div className='small-article-details'>
                  <h3>{article.title}</h3>
                  <p className='preview-date small-article-preview-date'> {`${article.postID.split(':')[1]}/${article.postID.split(':')[2]}/${article.postID.split(':')[0]}`}</p>
                </div>
              </div>
            
          ))}
        </div>
      </div>
    <div className="blog-list-container">
      {/* Display a stream of blog previews */}
      <ul className='blog-posts-list'>
      {metadata.map((post) => {
        // Split the timestamp to get individual components
        const [year, month, day, titleUrlFormatted] = post.postID.split(':');

        return (
          <li key={post.timestamp} className="blog-post">
            <Link to={`/blog/${year}/${month}/${day}/${titleUrlFormatted}`} className='blog-post-link'>
              {post.thumbnailUrl && <img src={post.thumbnailUrl} alt="Article Thumbnail" className="thumbnail" />}
              <h2 className='blog-page-title'>{post.title}</h2>
              <p className='preview-date'>{`${month}/${day}/${year}`}</p>
              <p className="snippet">{post.preview}...</p>
            </Link>
            
            <div className="divider"></div>
            
          </li>
        );
      })}
      </ul>
      
    </div>
    
    
    

    </div>
  );
};

export default BlogPage;
