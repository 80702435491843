// ProjectModal.js
import React from 'react';
import '../css/ProjectModal.css';
import { Link } from 'react-router-dom';

const ProjectModal = ({ project, onClose }) => {
  return (
    <div className="project-modal-overlay" onClick={onClose}>
      <div className="project-modal">
        <h3>{project.title}</h3>
        <p>{project.description}</p>
        <div className="project-links">
          {project.projectLink && (
            <Link to={project.projectLink} target="_blank" rel="noopener noreferrer">
              Link to Project
            </Link>
          )}
          {project.articleLink && (
            <Link to={project.articleLink} target="_blank" rel="noopener noreferrer">
              How It's Made Article
            </Link>
          )}
          {project.githubLink && (
            <Link to={project.githubLink} target="_blank" rel="noopener noreferrer">
              GitHub Link
            </Link>
          )}
        </div>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ProjectModal;
