// ProjectsEditor.js
import React from 'react';
import '../css/ProjectsEditor.css';
import Filters from './Filters';
import { useState, useEffect } from 'react';
import ProjectEditorModal from './ProjectEditorModal';
import config from '../config';

// delete if backend will return sorted by date
const ProjectsEditor = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [projects, setProjects] = useState([]);
  const [editedIDS, setEditedIDs] = useState(new Set());
  const [password, setPassword] = useState('');
  const [editedProjectData, setEditedProjectData] = useState({
    projectID: '',
    title: '',
    description: '',
    imageUrl: '',
    projectLink: '',
    articleLink: '',
    githubLink: '',
    tags: [],
    date: ''
  })

  const handlePublishEdits = async () => {
    try {
      
      const response = await fetch(`${config.apiUrl}/api/projects/publish`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ secret: password, projects: projects }),
      });

      const result = await response.json();

      if (result.success) {
        alert('Publish successful!');
      } else {
        alert('Publish failed. Please try again.');
      }
    } catch (error) {
      console.error('Error publishing edits:', error);
      alert('Publish failed. Please try again.');
    }
  };


  useEffect(() => {
    // Fetch all saved projects from the backend
    fetch(`${config.apiUrl}/api/projects`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setProjects(data);
        
        // Sort the projects by date
        const sortedProjects = [...data].sort((a, b) => new Date(a.date) - new Date(b.date));
        setProjects(sortedProjects);
      })
      .catch(error => {
        console.error('Error fetching projects:', error);
      });
  }, []);


  const openEditor = (project) => {
    setEditedProjectData(project);
    setSelectedProject(project);
  };

  const closeModal = (project) => {
    if (editedIDS.has(project.projectID))
    {
      setProjects((prevProjects) => {
        return prevProjects.map((iterProject) => {
          if (iterProject.projectID === editedProjectData.projectID) {
            return { ...iterProject, ...editedProjectData };
          }
          return iterProject;
        });
      });
    }
    setSelectedProject(null);
  };

  const newProject = () => {
    const newProjectID = projects.length + 1;
    const newProjectData = {
      projectID: newProjectID.toString(),
      title: '',
      description: '',
      imageUrl: '',
      projectLink: '',
      articleLink: '',
      githubLink: '',
      tags: [],
      date: ''
    };

    setProjects(prevProjects => [...prevProjects, newProjectData]);

    setEditedProjectData(newProjectData);
    setSelectedProject(newProjectData);
  }

  const handleTagSelect = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  // Filter projects based on selected tags
  const filteredProjects =
    selectedTags.length === 0
      ? projects
      : projects.filter((project) => project.tags.some((tag) => selectedTags.includes(tag)));

  return (
    <>
      <div className="page-header">
        <h1 className="page-title">My Projects</h1>
        <p className="page-description">Explore some of my featured projects below.</p>
        <Filters projects={projects} selectedTags={selectedTags} onSelectTag={handleTagSelect} />
      </div>
      <div className="projects-page-container">
        <div className="left-projects-pane">
          {filteredProjects.map((project, index) =>
            index % 2 === 0 ? (
              <div className={`project-bubble ${editedIDS.has(project.projectID) ? 'edited-book' : ''}`} key={project.projectID} onClick={() => openEditor(project)}>
                <img src={project.imageUrl} alt={project.title} />
                <h3>{project.title}</h3>
                <p><strong>{project.date}</strong></p>
                <p>{project.description}</p>
              </div>
            ) : null
          )}
        </div>
        <div className="right-projects-pane">
          {filteredProjects.map((project, index) =>
            index % 2 === 1 ? (
              <div className={`project-bubble ${editedIDS.has(project.projectID) ? 'edited-book' : ''}`} key={project.projectID} onClick={() => openEditor(project)}>
                <img src={project.imageUrl} alt={project.title} />
                <h3>{project.title}</h3>
                <p><strong>{project.date}</strong></p>
                <p>{project.description}</p>
              </div>
            ) : null
          )}
          <div className='project-bubble' key='newProject' onClick={() => newProject()}>
              <img src='plus.png' alt='add a new project'/>
              <h3>Add a New Project</h3>
          </div>
        </div>
        {selectedProject && <ProjectEditorModal setEditedProjectData={setEditedProjectData} editedProjectData={editedProjectData} setEditedIDs={setEditedIDs} setProjects={setProjects} project={selectedProject} onClose={() => closeModal(selectedProject)} />}
      </div>

      <input
        id='passwordInput'
        type='password'
        placeholder='Enter Secret'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <button onClick={handlePublishEdits} className="cancel-edit-button">
        Publish Edit(s)
      </button>
    </>
  );
};

export default ProjectsEditor;
