import React, { useState } from 'react';
import '../css/BlogEditor.css';
import config from '../config';

const BlogEditor = () => {
  const [isNewPost, setIsNewPost] = useState(true);
  const [postId, setPostId] = useState('');
  const [markdownFile, setMarkdownFile] = useState(null);
  const [password, setPassword] = useState('');
  const [metadata, setMetadata] = useState({
    preview: '',
    title: '',
    timestamp: '',
    postID: '',
    thumbnailUrl: '',
  });

  const handleToggle = () => {
    setIsNewPost(!isNewPost);
    setPostId(''); // Reset postId when toggling
  };

  const handlePostIdChange = (event) => {
    setPostId(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setMarkdownFile(file);
  };

  const handleMetadataChange = (field, value) => {
    setMetadata((prevMetadata) => ({ ...prevMetadata, [field]: value }));
  };

  const handleSearch = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/api/blogmetadata/${postId}`);
      if (response.ok) {
        const metadataData = await response.json();
        setMetadata(metadataData);
      } else {
        console.error('Failed to fetch metadata for post ID:', postId);
      }
    } catch (error) {
      console.error('Error during metadata fetch:', error);
    }
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('markdownFile', markdownFile);
      formData.append('metadata', JSON.stringify({ ...metadata }));
      formData.append('secret', password);

      const apiUrl = isNewPost ? `${config.apiUrl}/api/blog/create` : `${config.apiUrl}/api/blog/update`;
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        console.log(`Blog post ${isNewPost ? 'created' : 'updated'} successfully!`);
        // Optionally, you can handle further actions after a successful upload
        alert('Success'); // Alert success if needed
      } else {
        console.error(`Failed to ${isNewPost ? 'create' : 'update'} blog post.`);
        // Handle error cases, display an error message, etc.
        alert('Failed'); // Alert failure if needed
      }
    } catch (error) {
      console.error('Error during blog post operation:', error);
      alert('Error'); // Alert error if needed
    }
  };
  
  return (
    <section className="blog-editor-section">
      <h2>Blog Creator/Editor</h2>
      <p>Toggle below for creating a new post or updating an existing post</p>

      <label className="blog-editor-checkbox-label">
        <input
          type="checkbox"
          checked={isNewPost}
          onChange={handleToggle}
          className="blog-editor-checkbox"
        />
        Create New Post
      </label>

      {isNewPost ? (
        <div>
          <div className="blog-editor-file-upload">
            <label className="blog-editor-label">
              Upload Markdown File:
              <input type="file" accept=".md" onChange={handleFileChange} className="blog-editor-input" />
            </label>
          </div>
          <div className="blog-editor-metadata-preview">
            <label className="blog-editor-label">
              Preview (300 words):
              <textarea
                value={metadata.preview}
                onChange={(e) => handleMetadataChange('preview', e.target.value)}
                className="blog-editor-textarea"
              />
            </label>
          </div>
          <div className="blog-editor-title">
            <label className="blog-editor-label">
              Title:
              <input
                type="text"
                value={metadata.title}
                onChange={(e) => handleMetadataChange('title', e.target.value)}
                className="blog-editor-input"
              />
            </label>
          </div>
          <div className="blog-editor-thumbnail-url">
            <label className="blog-editor-label">
              Thumbnail URL:
              <input
                type="text"
                value={metadata.thumbnailUrl}
                onChange={(e) => handleMetadataChange('thumbnailUrl', e.target.value)}
                className="blog-editor-input"
              />
            </label>
          </div>
        </div>
      ) : (
        <div className='blog-editor-edit-padding'>
          <div className="blog-editor-update-post-id">
            <label className="blog-editor-label">
              Post ID to Update:
              <input
                type="text"
                value={postId}
                onChange={handlePostIdChange}
                placeholder="Enter Post ID"
                className="blog-editor-input"
              />
            </label>
          </div>
          <div className="blog-editor-file-upload">
            <label className="blog-editor-label">
              Upload Markdown File:
              <input type="file" accept=".md" onChange={handleFileChange} className="blog-editor-input" />
            </label>
          </div>
          <div className="blog-editor-metadata-preview">
            <label className="blog-editor-label">
              Preview (300 words):
              <textarea
                value={metadata.preview}
                onChange={(e) => handleMetadataChange('preview', e.target.value)}
                className="blog-editor-textarea"
              />
            </label>
          </div>
          <div className="blog-editor-thumbnail-url">
            <label className="blog-editor-label">
              Thumbnail URL:
              <input
                type="text"
                value={metadata.thumbnailUrl}
                onChange={(e) => handleMetadataChange('thumbnailUrl', e.target.value)}
                className="blog-editor-input"
              />
            </label>
          </div>
          <button
            onClick={handleSearch}
            className="blog-editor-button"
          >
            Search
          </button>
        </div>
      )}

      <div className="blog-editor-password">
        <label className="blog-editor-label">
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="blog-editor-input"
          />
        </label>
      </div>
      <button onClick={handleUpload} className="blog-editor-button">Upload</button>
    </section>
  );
};

export default BlogEditor;
