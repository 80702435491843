import React, { useState } from 'react';
import '../css/Dashboard.css';
import BlogEditor from './BlogEditor';
import BooksEditor from './BooksEditor';
import ProjectsEditor from './ProjectsEditor';

const Dashboard = () => {
  const [selectedPane, setSelectedPane] = useState('projects');

  const handleSelectPane = (pane) => {
    setSelectedPane(pane);
  };

  return (
    <div className='dashboard-container'>
      <div className='button-pane'>
        <button
          className={selectedPane === 'projects' ? 'active' : 'inactive'}
          onClick={() => handleSelectPane('projects')}
        >
          Projects
        </button>
        <button
          className={selectedPane === 'blog' ? 'active' : 'inactive'}
          onClick={() => handleSelectPane('blog')}
        >
          Blog
        </button>
        <button
          className={selectedPane === 'books' ? 'active' : 'inactive'}
          onClick={() => handleSelectPane('books')}
        >
          Books
        </button>
      </div>
      <div className='editor-pane'>
        {selectedPane === 'projects' && <ProjectsEditor />}
        {selectedPane === 'blog' && <BlogEditor />}
        {selectedPane === 'books' && <BooksEditor />}
      </div>
    </div>
  );
};

export default Dashboard;
