import React, { useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';

const MyMusic = () => {
  const waveContainerRef = useRef(null);
  const waveSurfers = useRef([]);

  useEffect(() => {
    
    const containers = waveContainerRef.current.querySelectorAll('.wave-container');

    if (waveSurfers.current.length === 0) {
        containers.forEach((container, index) => {
            
        const waveSurfer = WaveSurfer.create({
            container,
            waveColor: index === 0 ? '#1a1423' : index === 1 ? '#372549' : '#774c60',
            progressColor: '#eacdc2',
            cursorColor: 'white',
            responsive: true,
            barWidth: 2,
            barHeight: 1,
            barGap: null,
        });

        waveSurfers.current.push(waveSurfer);

        // Load audio file
        waveSurfer.load([
            'claire.mp3',
            'novo.mp3',
            'gitar.mp3',
        ][index]);
        });
    }
  }, []);

  const handlePlay = (index) => {
    let keepChecking = true;
    waveSurfers.current.forEach((waveSurfer, i) => {
      if (waveSurfer.isPlaying()) {
        waveSurfer.stop();
        keepChecking = false;
      }
    });

    if (keepChecking) {
      waveSurfers.current[index].play();
    }
  };

  return (
    <div ref={waveContainerRef}>
      <div className="wave-container" onClick={() => handlePlay(0)}></div>
      <div className="wave-container" onClick={() => handlePlay(1)}></div>
      <div className="wave-container" onClick={() => handlePlay(2)}></div>
    </div>
  );
};

export default MyMusic;
