import React, { useState, useEffect } from 'react';
import '../css/BooksEditor.css';
import config from '../config';

const BooksEditor = () => {
  const [books, setBooks] = useState({ fiction: [], nonFiction: [] });
  const [selectedBook, setSelectedBook] = useState(null);
  const [editorVisible, setEditorVisible] = useState(false);
  const [editedIDs, setEditedIDs]  = useState(new Set());
  const [password, setPassword] = useState('');
  const [editedBookData, setEditedBookData] = useState({
    bookID: '',
    Title: '',
    Author: '',
    ISBN: '',
    fewWords: '',
    dateRead: '',
    Link: '',
    isFiction: false,
  });

  useEffect(() => {
    // Fetch all saved books from the backend
    fetch(`${config.apiUrl}/api/books`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        // Ensure that the data has the expected structure before setting the state
        if (data && typeof data === 'object' && 'fiction' in data && 'nonFiction' in data) {
          setBooks(data);
        } else {
          console.error('Invalid data format for books:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching books:', error);
      });
  }, []);

  const handlePublishEdits = async () => {
    if (editedBookData.bookID !== '')
    {
      
      const bookType = editedBookData.isFiction ? 'fiction' : 'nonFiction';
    
      setBooks(prevBooks => {
        return {
          ...prevBooks,
          [bookType]: prevBooks[bookType].map(iterBook => {
            if (iterBook.bookID === editedBookData.bookID) {
              return { ...iterBook, ...editedBookData };
            }
            return iterBook;
          }),
        };
      });
    }
    
    try {
      
      const response = await fetch(`${config.apiUrl}/api/books/publish`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ secret: password, books: books.fiction.concat(books.nonFiction) }),
      });

      const result = await response.json();

      if (result.success) {
        alert('Publish successful!');
      } else {
        alert('Publish failed. Please try again.');
      }
    } catch (error) {
      console.error('Error publishing edits:', error);
      alert('Publish failed. Please try again.');
    }
  };

  const handleCancelEdit = () => {
    // Reset the selected book and content when canceling the edit
    setSelectedBook(null);
    setEditorVisible(false);
  };

  const handleBookClick = (book) => {
    setSelectedBook(book);
    setEditorVisible(true);

    if (editedBookData.bookID !== '')
    {
      
      const bookType = editedBookData.isFiction ? 'fiction' : 'nonFiction';
    
      setBooks(prevBooks => {
        return {
          ...prevBooks,
          [bookType]: prevBooks[bookType].map(iterBook => {
            if (iterBook.bookID === editedBookData.bookID) {
              return { ...iterBook, ...editedBookData };
            }
            return iterBook;
          }),
        };
      });
    }

    // Populate the edited values with the selected book's data
    setEditedBookData({
      Title: book.Title || '',
      Author: book.Author || '',
      ISBN: book.ISBN || '',
      fewWords: book.fewWords || '',
      dateRead: book.dateRead || '',
      Link: book.Link || '',
      isFiction: book.isFiction || false,
      bookID: book.bookID || ''
    });

  };

  const handleEdit = (e) => {
    
    setEditedIDs((prevSet) => new Set([...prevSet, editedBookData.bookID]));

    if (editedBookData.bookID !== '')
    {
      const bookType = editedBookData.isFiction ? 'fiction' : 'nonFiction';
    
      setBooks(prevBooks => {
        return {
          ...prevBooks,
          [bookType]: prevBooks[bookType].map(iterBook => {
            if (iterBook.bookID === editedBookData.bookID) {
              return { ...iterBook, ...editedBookData };
            }
            return iterBook;
          }),
        };
      });
    }
  }

  return (
    <section className="section">
      <h2>Books</h2>

      <div className="favorite-reads-list">
        <div className="left-pane">
          <h2>Fiction</h2>
          <ul>
            {books.fiction.map((book) => (
              <li key={book.bookID} onClick={() => handleBookClick(book)} className={`book-item ${editedIDs.has(book.bookID) ? 'edited-book' : ''}`}>
                {book.Title}
              </li>
            ))}
          </ul>
        </div>
        <div className="right-pane">
          <h2>Non-Fiction</h2>
          <ul>
            {books.nonFiction.map((book) => (
              <li key={book.bookID} onClick={() => handleBookClick(book)} className={`book-item ${editedIDs.has(book.bookID) ? 'edited-book' : ''}`}>
                {book.Title}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {editorVisible && (
        <div className="editor-container">
          <h3>{selectedBook.Title}</h3>

          <input
            id='titleinput'
            value={editedBookData.Title}
            onChange={(e) => {setEditedBookData({ ...editedBookData, Title: e.target.value });handleEdit(e)}}
          />
          <input
            id='authorinput'
            value={editedBookData.Author}
            onChange={(e) => {setEditedBookData({ ...editedBookData, Author: e.target.value }); handleEdit(e)}}
          />
          <input
            id='isbninput'
            value={editedBookData.ISBN}
            onChange={(e) => {setEditedBookData({ ...editedBookData, ISBN: e.target.value }); handleEdit(e)}}
          />
          <textarea
            id='fewWordsInput'
            value={editedBookData.fewWords}
            onChange={(e) => {setEditedBookData({ ...editedBookData, fewWords: e.target.value }); handleEdit(e)}}
          />
          <input
            id='datereadinput'
            value={editedBookData.dateRead}
            onChange={(e) => {setEditedBookData({ ...editedBookData, dateRead: e.target.value }); handleEdit(e)}}
          />
          <input
            id='linkbook'
            value={editedBookData.Link}
            onChange={(e) => {setEditedBookData({ ...editedBookData, Link: e.target.value }); handleEdit(e)}}
          />
          {editedBookData.isFiction ? (
            <p>This input should be a fiction book.</p>
          ) : (
            <p>This input should be a non-fiction book.</p>
          )}

          <input
            id='isFiction'
            type='checkbox'
            checked={editedBookData.isFiction}
            onChange={(e) => setEditedBookData({ ...editedBookData, isFiction: e.target.checked })}
          />
          <button onClick={handleCancelEdit} className="cancel-edit-button">
            Cancel Edit
          </button>
        </div>
      )}

      <input
        id='passwordInput'
        type='password'
        placeholder='Enter Secret'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <button onClick={handlePublishEdits} className="cancel-edit-button">
        Publish Edit(s)
      </button>
    </section>
  );
};

export default BooksEditor;
