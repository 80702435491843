import React from 'react';
import { Button, Timeline } from 'flowbite-react';
import { HiArrowNarrowRight, HiCalendar } from 'react-icons/hi';


const WorkHistory = () => {

  const LockheedLogoIcon = () => (
    <img src={'lockheed.png'} alt="Lockheed Logo" style={{ width: '24px', height: '24px', borderRadius: '50%' }} />
  );
  
  const ClearEdgeLogoIcon = () => (
    <img src={'clearedge.jpeg'} alt="ClearEdge Logo" style={{ width: '24px', height: '24px', borderRadius: '50%' }} />
  );

  const EazittLogoIcon = () => (
    <img src={'eazitt.png'} alt="ClearEdge Logo" style={{ width: '24px', height: '24px', borderRadius: '50%' }} />
  );

  return (
    <div>
      <h2>
        Experience
      </h2>
      <Timeline>
        <Timeline.Item>
          <Timeline.Point icon={LockheedLogoIcon} />
          <Timeline.Content>
            <Timeline.Time>Summer 2023 - Present</Timeline.Time>
            <Timeline.Title>Software Engineer Associate @ Lockheed Martin</Timeline.Title>
            <Timeline.Body>
              Writing and maintaining production level code, leading and partaking in formal reviews. Working in an Agile development environment,
              creating consistent automated tests, leading comprehensive verification reports
            </Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
        <Timeline.Item>
          <Timeline.Point icon={ClearEdgeLogoIcon} />
          <Timeline.Content>
            <Timeline.Time>Summer 2021 - Summer 2022</Timeline.Time>
            <Timeline.Title>Software Engineer Intern @ ClearEdge IT Solutions</Timeline.Title>
            <Timeline.Body>
              Led EDA efforts to develop story point estimation tools with NLP. Worked closely with the lead developer on an internal app in full stack work, containerization, routing and deployments.
            </Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
        <Timeline.Item>
          <Timeline.Point icon={EazittLogoIcon} />
          <Timeline.Content>
            <Timeline.Time>Fall 2022 - Spring 2023</Timeline.Time>
            <Timeline.Title>Backend Engineer Intern @ Eazitt</Timeline.Title>
            <Timeline.Body>
              Configured the CI/CD pipeline for our Serverless deployments which included an full coverage API testing suite I developed.
            </Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
      </Timeline>
    </div>
  );
};

export default WorkHistory;
