// NavBar.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../css/NavBar.css';

const NavBar = () => {
  return (
    <nav className="navbar">
      <div className="container">
        <Link to="/" className="nav-link">Home</Link>
        <Link to="/projects" className="nav-link">Projects</Link>
        <Link to="/blog" className="nav-link">Blog</Link>
        <Link to="/contact" className="nav-link">Contact</Link>
        <Link to="/notify" className="nav-link">Keep in Touch</Link>
      </div>
    </nav>
  );
};

export default NavBar;
