// Subscribe.js

import React, { useState } from 'react';
import axios from 'axios';
import '../css/Subscribe.css';
import config from '../config';

const Subscribe = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setMessage('Please enter a valid email address.');
      return;
    }

    try {
      const response = await axios.post(`${config.apiUrl}/api/subscribe`, { email });
      setMessage(response.data.message);
      setEmail('');
    } catch (error) {
      setMessage('An error occurred, please try again later.');
    }
  };

  const validateEmail = (email) => {
    // Basic email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <div className="subscribe-container">
      <div className="subscribe-content">
        <h2>Sign up for notifications</h2>
        <p>Don't worry, it's not as bad as it sounds. I will probably just shoot you a personal email whenever I post something I am excited about.</p>
        <form onSubmit={handleSubmit}>
          <input type="email" placeholder="Enter your email" value={email} onChange={handleChange} />
          <button type="submit">Sign Up For Notifications</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default Subscribe;
