// Contact.js

import React, { useState } from 'react';
import { FaEnvelope, FaStrava, FaLinkedin, FaGithub, FaCode, FaSpotify } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import '../css/Contact.css';

const Contact = () => {
    const [showNotification, setShowNotification] = useState(false);

    const handleCopyToClipboard = (text) => {
        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = text;
      
        // Append the textarea to the document
        document.body.appendChild(textarea);
      
        // Select and copy the text
        textarea.select();
        document.execCommand('copy');
      
        // Remove the temporary textarea
        document.body.removeChild(textarea);

        // Show the notification
        setShowNotification(true);

        setTimeout(() => {
            setShowNotification(false);
        }, 2000);
      };

        return (
        <div className="contact-container">
            <div className={`copy-notification ${showNotification ? 'show-notification' : ''}`}>
                        Copied to Clipboard!
            </div>
            <div className="about-me">
            <h2>Hey!</h2>
            <p className="bio-text">
                I'm Jake, a programmer and professional bug stomper with a variety of interests from Machine Learning to Full Stack Development, and everything in between. 
                Feel free to reach out with any questions or comment and I'll get back to you asap!
            </p>
            <div className='header-divider-light'/>
            <div className="contact-details">
                <div className="contact-item email" onClick={() => handleCopyToClipboard('jakebaldwin17@gmail.com')}>
                    <FaEnvelope className="icon" />
                    <p>jakebaldwin17@gmail.com</p>
                </div>  
                
                <Link to="https://www.linkedin.com/in/jake-p-baldwin/" target="_blank" className='custom-link'>
                    <div className="contact-item linkedin">
                        <FaLinkedin className="icon" />
                        <p>LinkedIn</p>
                    </div>
                </Link>

                <Link to="https://github.com/jakebaldwin" target="_blank" className='custom-link'>
                    <div className="contact-item github">
                        <FaGithub className="icon" />
                        <p>GitHub</p>
                    </div>
                </Link>

                <Link to="https://leetcode.com/JakeBaldwin/" target="_blank" className='custom-link'>
                    <div className="contact-item leet">
                        <FaCode className="icon" />
                        <p>LeetCode</p>
                    </div>
                </Link>

                <Link to="https://www.strava.com/athletes/91819036" target="_blank" className='custom-link'>
                    <div className="contact-item strava">
                        <FaStrava className="icon" />
                        <p>Strava</p>
                    </div>
                </Link>

                <Link to="https://open.spotify.com/user/jakebaldwin17?si=aa4c1e51a120438e" target="_blank" className='custom-link'>
                    <div className="contact-item spotify">
                        <FaSpotify className="icon" />
                        <p>Spotify</p>
                    </div>
                </Link>
            </div>
            </div>
            
            <div className="profile-container">
                <img className="profile-picture" src="ski.jpg" alt="Profile" />
            </div>
        </div>
        );
};

export default Contact;
