// ProjectEditorModal.js
import React from 'react';
import '../css/ProjectEditorModal.css';

const ProjectEditorModal = ({ setEditedProjectData, editedProjectData, setEditedIDs, setProjects, project, onClose }) => {

      const handleEdit = (e) => {
    
        setEditedIDs((prevSet) => new Set([...prevSet, editedProjectData.projectID]));


        setProjects((prevProjects) => {
            return prevProjects.map((iterProject) => {
            if (iterProject.projectID === editedProjectData.projectID) {
                return { ...iterProject, ...editedProjectData };
            }
            return iterProject;
            });
        });
        
  }

  return (
    <div className="project-modal-overlay">
      <div className="project-editor-modal">
      <div className="project-input">
          <label>Title:</label>
          <input
            type="text"
            value={editedProjectData.title}
            onChange={(e) => {setEditedProjectData({...editedProjectData, title: e.target.value});handleEdit(e)}}
          />
        </div>

        <div className="project-input">
          <label>Description:</label>
          <input
            type="text"
            value={editedProjectData.description}
            onChange={(e) => {setEditedProjectData({...editedProjectData, description: e.target.value});handleEdit(e)}}
          />
        </div>

        <div className="project-input">
          <label>Article Link:</label>
          <input
            type="text"
            value={editedProjectData.articleLink}
            onChange={(e) => {setEditedProjectData({...editedProjectData, articleLink: e.target.value});handleEdit(e)}}
          />
        </div>

        <div className="project-input">
          <label>Project Link:</label>
          <input
            type="text"
            value={editedProjectData.projectLink}
            onChange={(e) => {setEditedProjectData({...editedProjectData, projectLink: e.target.value});handleEdit(e)}}
          />
        </div>

        <div className="project-input">
          <label>Date:</label>
          <input
            type="text"
            value={editedProjectData.date}
            onChange={(e) => {setEditedProjectData({...editedProjectData, date: e.target.value});handleEdit(e)}}
          />
        </div>

        <div className="project-input">
          <label>Github Link:</label>
          <input
            type="text"
            value={editedProjectData.githubLink}
            onChange={(e) => {setEditedProjectData({...editedProjectData, githubLink: e.target.value});handleEdit(e)}}
          />
        </div>

        <div className="project-input">
          <label>Image URL:</label>
          <input
            type="text"
            value={editedProjectData.imageUrl}
            onChange={(e) => {setEditedProjectData({...editedProjectData, imageUrl: e.target.value});handleEdit(e)}}
          />
        </div>

        <div className="project-input">
          <label>Tags:</label>
          <input
            type="text"
            value={editedProjectData.tags.join(',')}
            onChange={(e) => {
              const tagsArray = e.target.value.split(',')
              setEditedProjectData({...editedProjectData, tags: tagsArray});
              handleEdit(e)}}
          />
        </div>
        
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ProjectEditorModal;
