import { WorldMap } from "react-svg-worldmap";

const data = [
  { country: "us", value: "been here" }, // United States
  { country: "fr", value: "been here" }, // France
  { country: "gb", value: "been here" }, // Great Britain
  { country: "es", value: "been here" }, // Spain
  { country: "cz", value: "been here" }, // Czechia
  { country: "it", value: "been here" }, // Italy
];

function MyMap() {
  return (
    <div>
      <WorldMap color="green" size="responsive" data={data} />
    </div>
  );
}

export default MyMap;
