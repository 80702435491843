// EducationSection.js

import React from 'react';
import '../css/EducationSection.css';

const EducationSection = () => {
  return (
    <>
    <h2>Schooling</h2>
    <div className="education-section">
      <div className="education-details">
        
        <div className='education-left'>
          <p>Double Major in Computer Science and Pure Mathematics with a Machine Learning specialization</p>
          <p><i>Cum Laude</i></p>
          <p>University of Maryland, College Park</p>
          <p>Fall 2019 - Spring 2023</p>
        </div>
        <img src="UMD.png" alt="School Logo" />
      </div>
    </div>
    </>
  );
};

export default EducationSection;
