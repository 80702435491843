// FavoriteReadsList.js
import React from 'react';
import '../css/FavoriteReadsList.css';

const FavoriteReadsList = ({ favoriteFiction, favoriteNonFiction }) => {
  return (
    <div className="favorite-reads-container">
      <h2 className='books-header'>Books</h2>
      <p>
        <a
          href="https://www.goodreads.com/user/show/98306999-jake-baldwin"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check out the link to my Goodreads for more recommendations
        </a>
      </p>
      <div className="favorite-reads-list">
        <div className="pane">
          <h3>Favorite Fiction Reads</h3>
          <ul className='favorite-reads-book-list'>
            {favoriteFiction.map((book, index) => (
              <li key={index} className="book-card">
                <a href={book.Link} target="_blank" rel="noopener noreferrer">
                  <div>
                    <strong>{book.Title}</strong> by {book.Author}
                    <p className='fewwords'>{book.fewWords}</p>
                  </div>
                  <div className="read-date">(Read {book.dateRead})</div>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="pane">
          <h3>Favorite Non-Fiction Reads</h3>
          <ul className='favorite-reads-book-list'>
            {favoriteNonFiction.map((book, index) => (
              <li key={index} className="book-card">
                <a href={book.Link} target="_blank" rel="noopener noreferrer">
                  <div>
                    <strong>{book.Title}</strong> by {book.Author}
                    <p className='fewwords'>{book.fewWords}</p>
                  </div>
                  <div className="read-date">(Read {book.dateRead})</div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FavoriteReadsList;
